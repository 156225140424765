@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .underline-animate:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #dd1a1a;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out;
  }

  .underline-animate:hover:before {
    visibility: visible;
    transform: scaleX(1);
  }
}
